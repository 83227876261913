body {
  color: #fff;
  background-color: #570773;
  margin: 0;
  padding: 16px;
  font-family: Lexend, sans-serif;
}

.gPMrEW_header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.gPMrEW_header h1 {
  font-weight: inherit;
  font-size: calc(max(10px + 6vmin, 1.2em));
}

.gPMrEW_header .gPMrEW_logo {
  height: calc(max(40px + 36vmin, 1.2em));
}

.xL7ZpG_container {
  flex-direction: row;
  gap: 16px;
  display: flex;
}

.xL7ZpG_container > div {
  flex-grow: 1;
}

.xL7ZpG_players {
  max-width: 400px;
}

.xL7ZpG_heading {
  margin: 16px 0;
}

.xL7ZpG_communityCards, .xL7ZpG_hand {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.xL7ZpG_hand {
  margin: 4px 0;
}

.xL7ZpG_roundCards {
  border: 2px solid color-mix(in srgb, var(--token-colour) 90%, white);
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  padding: 8px;
  display: flex;
}

.xL7ZpG_roundCards.xL7ZpG_futureRound {
  border-style: dashed;
}

.xL7ZpG_handScore {
  visibility: visible;
}

.xL7ZpG_card, .xL7ZpG_noCard {
  box-sizing: border-box;
  vertical-align: top;
  border-radius: 8px;
  width: 36px;
  height: 48px;
  padding: 8px 6px;
  display: inline-block;
}

.xL7ZpG_noCard {
  background-color: #ffffff1a;
}

.xL7ZpG_card {
  color: #000;
  text-align: center;
  background-color: #fff;
  font-size: 20px;
  line-height: 15px;
}

.xL7ZpG_card:first-line {
  font-size: 18px;
}

.xL7ZpG_cardJoker {
  font-size: 28px;
  line-height: 28px;
}

.xL7ZpG_redSuit {
  color: #e00;
}

.xL7ZpG_blackSuit {
  color: #000;
}

.xL7ZpG_cardHighlight {
  z-index: 1;
  background-color: #d7f0ff;
  outline: 2px solid #000;
}

.xL7ZpG_cardClickable {
  cursor: pointer;
  box-shadow: 0 0 2px 2px #add8e6;
}

.xL7ZpG_cardStack {
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  display: flex;
}

.xL7ZpG_player {
  border-radius: 8px;
  margin: 4px;
  padding: 12px;
}

.xL7ZpG_highlightPlayer {
  background: #ffffff1a;
}

.xL7ZpG_playerName {
  font-weight: bold;
}

.xL7ZpG_tokenPool {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  display: flex;
}

.xL7ZpG_token, .xL7ZpG_noToken {
  box-sizing: border-box;
  font: inherit;
  text-align: center;
  vertical-align: top;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  font-size: 22px;
  font-weight: 550;
  display: inline-block !important;
}

.xL7ZpG_noToken {
  border: 2px dashed #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.xL7ZpG_token1 {
  --token-colour: #1d8b6c;
}

.xL7ZpG_token2 {
  --token-colour: #598b04;
}

.xL7ZpG_token3 {
  --token-colour: #a9540a;
}

.xL7ZpG_token4 {
  --token-colour: #ad0524;
}

.xL7ZpG_token {
  background: url("token.59b6bd29.svg"), var(--token-colour);
  color: #000;
  border: none;
  margin: -2px;
  padding: 0;
}

.xL7ZpG_token:hover:not(:disabled) {
  background: radial-gradient(#0000001a, #0000001a), url("token.59b6bd29.svg"), var(--token-colour);
}

.xL7ZpG_token:active:not(:disabled) {
  background: radial-gradient(#0003, #0003), url("token.59b6bd29.svg"), var(--token-colour);
}

.xL7ZpG_token.xL7ZpG_pastToken {
  visibility: visible;
}

.xL7ZpG_smallToken {
  vertical-align: middle;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.xL7ZpG_revealLog {
  flex-direction: column;
  margin: 4px 0;
  display: flex;
}

.xL7ZpG_gameResult {
  visibility: visible;
}

.xL7ZpG_log {
  flex-direction: column-reverse;
  display: flex;
}

.xL7ZpG_log h2 {
  order: 1;
}

.xL7ZpG_roundLog {
  flex-direction: column-reverse;
  display: flex;
}

.xL7ZpG_roundLogEntry {
  visibility: visible;
}

.xL7ZpG_winRecord {
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.xL7ZpG_winRecord .xL7ZpG_win, .xL7ZpG_winRecord .xL7ZpG_notWin {
  box-sizing: border-box;
  border-radius: 12px;
  width: 20px;
  height: 20px;
}

.xL7ZpG_winRecord .xL7ZpG_win {
  border: 4px solid #90ee90;
}

.xL7ZpG_winRecord .xL7ZpG_notWin {
  border: 4px solid #0000004d;
}

.xL7ZpG_winRecord .xL7ZpG_loss, .xL7ZpG_winRecord .xL7ZpG_notLoss {
  text-align: center;
  width: 20px;
  height: 20px;
  font-size: 36px;
  font-weight: bold;
  line-height: 14px;
}

.xL7ZpG_winRecord .xL7ZpG_loss:after, .xL7ZpG_winRecord .xL7ZpG_notLoss:after {
  content: "×";
}

.xL7ZpG_winRecord .xL7ZpG_loss {
  color: red;
}

.xL7ZpG_winRecord .xL7ZpG_notLoss {
  color: #0000004d;
}
/*# sourceMappingURL=index.36e35cc0.css.map */
